import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../auth/Slice'
import branchReducer from '../features/branch/Slice'
import applicationReducer from '../features/application/Slice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    branch: branchReducer,
    application: applicationReducer,
  }
})
