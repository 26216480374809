import React, { useState, useEffect } from "react";
import { useHistory, NavLink } from 'react-router-dom';
import { Grid, Button, Avatar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MUIDataTable from "mui-datatables";

// api
import { ApiService } from "../../api/ApiService";

// components
import PageTitle from "../../components/PageTitle/PageTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  fit: {
    height: '100%',
    width: '100%',
  },
}));

export default function Tables() {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  
  var [data, setData] = useState([]);

  useEffect(function() {
    ApiService.getList("api/v1/customer", {}).then(
      response => {
        console.log(response.data);
        
        var tableData = response.data.dataList.map(item => {
          var result = {
            id: item.id,
            name: item.name,
            email: item.email,
            phoneNumber: item.phoneNumber
          }

          return result;
        });

        setData(tableData);
      },
      error => {
        console.log(error);
      }
    );
  }, []);

  return (
    <>
      <ToastContainer />
      <PageTitle title={t("customer.list")} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            data={data}
            columns={[
              { name: "name", label: t("customer.name") },
              { name: "email", label: t("customer.email") },
              { name: "phoneNumber", label: t("customer.phone") }
            ]}
            options={{
              filterType: "textField",
              selectableRowsHideCheckboxes: true,
              textLabels: {
                body: {
                  noMatch: t("tables.no_data"),
                }
              },
              download: false,
              print: false
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
