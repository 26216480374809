import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'branch',
  initialState: {
      currentDataItem: {},
      currentDataItemUpdated: false,
  },
  reducers: {
    selectDataItem: (state, action) => {
      state.currentDataItem = action.payload;
      state.currentDataItemUpdated = false;
    },
    updateDataItem: (state, action) => {
      console.log(action);

      state.currentDataItem[action.payload.field] = action.payload.value;
      state.currentDataItemUpdated = true;

      console.log(state.currentDataItem);
    },
    saveDataItem: (state, action) => {
      state.currentDataItemUpdated = false;
    },
  }
})

// export actions
export const { selectDataItem, updateDataItem, saveDataItem } = slice.actions

// export reducer
export default slice.reducer