import { UserManager } from 'oidc-client';
import { loginSucceed, logoutSucceed } from './Slice';
import { store } from '../app/store';

const settings = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_AUTH_SILENT_REDIRECT_URI,
  response_type: "code",
  scope: process.env.REACT_APP_AUTH_SCOPE,
  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
};

const userManager = new UserManager(settings);

const AuthService = {};

AuthService.login = () => {
  var fakeUser = {
    name: "Demo User",
  };
  store.dispatch(loginSucceed(fakeUser));
};

AuthService.loginCallback = () => {
  return userManager.signinRedirectCallback().then((user) => {
    var loginUser = {
      access_token: user.access_token,
      id_token: user.id_token,
      sid: user.profile.sid,
      sub: user.profile.sub,
      name: user.profile.name,
    };
    store.dispatch(loginSucceed(loginUser));
  });
};

AuthService.logout = (user) => {
  localStorage.removeItem("current_login_user");
  if (process.env.REACT_APP_AUTH_ENABLED === "true") {
    return userManager.signoutRedirect({ id_token_hint: user.id_token });
  } else {
    store.dispatch(logoutSucceed(user));
  }
};

AuthService.logoutCallback = () => {
  return userManager.signoutRedirectCallback().then((user) => {
    store.dispatch(logoutSucceed(user));
  });
};

AuthService.getUser = () => {
  return userManager.getUser();
};

AuthService.renewToken = () => {
  return userManager.signinSilent();
};

export default AuthService;
