import UnderConstruction from "./utils/UnderConstruction";
import UserList from "./features/user/List";
import UserDetail from "./features/user/Detail";
import CustomerList from "./features/customer/List";
import CustomerDetail from "./features/customer/Detail";
import BookingList from "./features/booking/List";
import BookingDetail from "./features/booking/Detail";

const routes = [
  { path: "/users", component: UserList, exact: true },
  { path: "/users/:id", component: UserDetail },
  { path: "/customers", component: CustomerList },
  { path: "/bookings", component: BookingList },
];

export default routes;
