import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

// routes
import routes from "../../routes"

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              {routes.map((item, index) => (
                item.redirect === undefined ?
                <Route key={index} path={item.path} component={item.component} exact={item.exact} /> :
                <Route key={index} path={item.path} component={item.component} exact={item.exact} render={() => <Redirect to={item.redirect} />} />
              ))}
            </Switch>
            <div className={classes.footer}>{process.env.NODE_ENV} - v1.0.3 - 2021.11.16.1336</div>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
