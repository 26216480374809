import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// components
import Layout from "./components/Layout/Layout";

// auth
import AuthService from "./auth/AuthService"

const history = createBrowserHistory();

export default function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" render={() => <Redirect to="/dashboard" />} exact />
        <Route path="/" component={Layout} />
        <PublicRoute path="/login" component={() => AuthService.login()} />
        <PublicRoute path="/login_callback" component={LoginCallback} />
        <PublicRoute path="/logout_callback" component={LogoutCallback} />
      </Switch>
    </Router>
  );

  function PrivateRoute({ component, ...rest }) {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }

  function LoginCallback() {
    const history = useHistory()
  
    useEffect(() => {
      async function loginAsync() {
        await AuthService.loginCallback()
        // redirect user to home page
        history.push('/')
      }
      loginAsync()
    }, [history]);
  
    return <></>;
  }

  function LogoutCallback() {
    const history = useHistory();

    useEffect(() => {
      async function logoutAsync() {
        await AuthService.logoutCallback();

        history.push("/login");
      }
      logoutAsync();
    }, [history]);
  
    return <></>;
  }
}
