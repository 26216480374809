import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Table, TableBody, TableRow, TableCell, Typography, IconButton } from '@material-ui/core';
import { Edit as EditIcon, ArrowBack as CancelIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

// api
import { api as ApplicationApi } from "../../api/ApplicationApi";

// slice
import { setDataItem } from './Slice';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormGroup-row': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0)
    },
    '& .MuiFormControl-root': {
      marginTop: theme.spacing(1)
    },
  },
  table: {
    '& .MuiTableRow-root': {
      height: 16,
    },
    '& .MuiTableCell-root': {
      fontSize: "0.5rem",
    },
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  moreButton: {
    margin: -theme.spacing(1),
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.text.hint,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "rgba(255, 255, 255, 0.35)",
    },
  },
}));

const ApplicationDetail = ({ currentData, setCurrentData }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const { id } = useParams();

  useEffect(function() {
    if (id) {
      ApplicationApi.getDetail(id).then(
        response => {
          setCurrentData(response);
        },
        error => {
          
        }
      );
    }
    else {
      setCurrentData({});
    }

  }, [id, setCurrentData]);

  return (
    <>
      <PageTitle title={currentData["Name"]} button={
        <div>
          <Button
            variant="contained"
            size="medium"
            startIcon={<CancelIcon />}
            style={{ marginLeft: 10 }}
            onClick={() => {
              history.push("/app/applications");
            }}
          >
            {t("operation.back_list")}
          </Button>
        </div>
      } />
      
      <Grid container spacing={4}>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Widget bodyClass={classes.tableWidget} header={
            <div className={classes.mainChartHeader}>
              <Typography variant="h5">Basic Info</Typography>
              <IconButton color="primary" aria-owns="widget-menu" aria-haspopup="true" classes={{ root: classes.moreButton }}
                onClick={() => {}}
              >
                <EditIcon />
              </IconButton>
            </div>
          }>
            <Table className={classes.table} size="small">
              <TableBody>
                {Object.entries(currentData).filter(([key, value]) => key.indexOf("Crunchbase") === -1)
                .filter(([key, value]) => key.indexOf("Github") === -1)
                .map(([key, value], index) => (
                  <TableRow key={index}>
                    <TableCell><Typography variant="caption" noWrap style={{fontWeight: "bold", fontSize: "0.5rem" }}>{key}</Typography></TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Widget>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Widget bodyClass={classes.tableWidget} header={
            <div className={classes.mainChartHeader}>
              <Typography variant="h5">Crunchbase</Typography>
              <IconButton color="primary" aria-owns="widget-menu" aria-haspopup="true" classes={{ root: classes.moreButton }}
                onClick={() => {}}
              >
                <EditIcon />
              </IconButton>
            </div>
          }>
            <Table className={classes.table} size="small">
              <TableBody>
                {Object.entries(currentData).filter(([key, value]) => key.indexOf("Crunchbase") > -1)
                .map(([key, value], index) => (
                  <TableRow key={index}>
                    <TableCell><Typography variant="caption" noWrap style={{fontWeight: "bold", fontSize: "0.5rem" }}>{key}</Typography></TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Widget>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Widget bodyClass={classes.tableWidget} header={
            <div className={classes.mainChartHeader}>
              <Typography variant="h5">Github</Typography>
              <IconButton color="primary" aria-owns="widget-menu" aria-haspopup="true" classes={{ root: classes.moreButton }}
                onClick={() => {}}
              >
                <EditIcon />
              </IconButton>
            </div>
          }>
            <Table className={classes.table} size="small">
              <TableBody>
                {Object.entries(currentData).filter(([key, value]) => key.indexOf("Github") > -1)
                .map(([key, value], index) => (
                  <TableRow key={index}>
                    <TableCell><Typography variant="caption" noWrap style={{fontWeight: "bold", fontSize: "0.5rem" }}>{key}</Typography></TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentData: state.application.currentDataItem,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentData: (data) => dispatch(setDataItem(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetail);
