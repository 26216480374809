import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: JSON.parse(localStorage.getItem("current_login_user")),
    isAuthenticated: !!localStorage.getItem("current_login_user"),
  },
  reducers: {
    loginSucceed: (state, action) => {
      state.currentUser = action.payload;
      state.isAuthenticated = true;

      localStorage.setItem("current_login_user", JSON.stringify(action.payload));
    },
    logoutSucceed: (state, action) => {
      state.currentUser = {};
      state.isAuthenticated = false;
    },
  }
})

// export actions
export const { loginSucceed, logoutSucceed } = slice.actions

// export reducer
export default slice.reducer
