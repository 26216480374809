import axios from 'axios';

const API_BASE_URL = "https://ogm.origintek.com/" //process.env.REACT_APP_API_BASE_URL;

export const ApiEndpoint = {
  user: "user",
  application: "applications",
  applicationType: "applicationtype",
  branch: 'branch',
  businessUnit: 'businessunit',
  vendor: 'vendor'
}

export const ApiService = {
  getList(endpoint, params) {
    console.log(params);
    return axios.get(API_BASE_URL + endpoint, {params: params});
  },
  
  getDetail(endpoint, id) {
    console.log(id);
    return axios.get(API_BASE_URL + endpoint + "/" + id);
  },
  
  createData(endpoint, data) {
    console.log(data);
    return axios.post(API_BASE_URL + endpoint, data);
  },
  
  updateData(endpoint, id, data) {
    console.log(data);
    return axios.put(API_BASE_URL + endpoint + "/" + id, data);
  },
  
  deleteData(endpoint, data) {
    console.log(data);
    return axios.delete(API_BASE_URL + endpoint, {data: data});
  }
}