import { ApiService } from "./ApiService";

const endpoint = "applications";

export const api = {
  getList(params) {
    console.log(params);
    return ApiService.getList(endpoint, {}).then(
      response => {
        console.log(response.data);
        return response.data;
      },
      error => {
        console.log(error);
      }
    );
  },
  
  getDetail(id) {
    console.log(id);
    return ApiService.getDetail(endpoint, id).then(
      response => {
        console.log(response.data);
        return response.data;
      },
      error => {
        console.log(error);
      }
    );
  },
}